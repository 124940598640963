@use '@edwin-edu/ui/vars' as *;

.welcomeDesc {
  font-weight: 500;
  font-size: 18px;
  min-height: 40px;
  display: flex;
  align-items: center;
}

.message {
  margin-bottom: 25px;
  line-height: 1.2;
  text-align: left;
}

.spinnerText {
  display: block;
  color: $color-base-300;
  font-size: 13px;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
}

.spinnerText {
  display: block;
  color: $color-base-300;
  font-size: 13px;
  font-style: italic;
  margin: 0 auto;
  text-align: center;
}

.signInWithEmail {
  cursor: pointer;
  text-decoration: underline;
}

.displayEmailFieldContainer {
  display: block;
}

.signInInfo {
  font-weight: 500;
  font-size: 18px;
  letter-spacing: -0.6px;
  line-height: 22px;
  width: 250px;
  text-align: center;
  margin: $space-6x auto $space-4x;
}

.orRow {
  display: block;
  position: relative;
  width: 160px;
  border-bottom: 1px solid $color-neutral-300;
  height: 13px;
  margin: $space-4x 0 $space-6x;
}

.orRowText {
  //span
  line-height: 1.25;
  padding: 3px 10px 0;
  font-size: 12px;
}

.anchor {
  &:focus {
    outline: 2px solid $color-base-300;
  }
}

.margin {
  height: 11px;
}

.center {
  text-align: center;
}

.authInput {
  //input
  outline: none;
  height: 41px;
  margin: 4px auto 4px;
  padding: 0 16px;
  width: 325px;
  border-radius: 20px;
  text-align: center;
  letter-spacing: -0.3px;
  background: rgba($color-base-300, 0.25);
  border: none !important;

  &::-moz-placeholder,
  -webkit-input-placeholder {
    font-style: italic;
    color: $color-neutral-500;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }
}

.continueButton {
  //Button
  padding: 1px 18px 2px;
  margin: 5px auto 15px;
  border: 1px solid $color-neutral-400 !important;
  letter-spacing: -0.2px;
  font-size: 12px;
}

.bottomRow {
  //form
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;

  span {
    a {
      color: $color-base-300;
      text-decoration: underline;
      font-weight: 700;

      &:focus {
        outline: 2px solid $color-base-300;
      }
    }
  }
}

.helpLink {
  //a
  font-size: 12px;
  color: $color-neutral-500 !important;
  text-decoration: none;
  letter-spacing: -0.2px;
  // margin-bottom: 15px;
  -webkit-user-drag: none;
  margin-top: 25px;
  font-weight: 600;

  &:focus {
    outline: 2px solid $color-base-300;
  }
}

.errorContainer {
  padding: 20px 0;
  text-align: center;
}

.errorNumber {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 14px;
  letter-spacing: -0.5px;
  margin-top: 5px;
}

.errorTitle {
  font-weight: 600;
  font-size: 24px;
  letter-spacing: -0.6px;
  margin: 15px auto 9px;
}

.errorDesc {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.4px;
  width: 327px;
  margin: 0 auto;
  padding-bottom: 8px;
}

.errorSubDesc {
  font-weight: 600;
  color: $color-base-300;
  font-size: 13px;
  letter-spacing: -0.3px;
  width: 300px;
  margin: 10px auto;
}

.contact {
  font-weight: 700;
  text-decoration: none;
  color: $color-base-300;
  padding-left: 2px;
}

.tryAgainButton {
  margin: 20px auto 0;
}

.outerAuthContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerAuthContainer {
  position: relative;
  width: 460px;
  background: $color-neutral-0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 40px;
  z-index: 10;
  color: $color-neutral-500;
  min-height: 400px;

  background: linear-gradient(90.7deg,
      #ffffff 46.73%,
      rgba(255, 255, 255, 0.4) 106.42%);
  mix-blend-mode: normal;
  opacity: 0.95;
  /* TODO: opacity: 0.9 with transform: translate(-50%, -50%); is causing blurry text when editing input fields */

  /* phia/shadow-temp */
  box-shadow: 0 8px 12px rgba(74, 74, 74, 0.25);
  border-radius: 8px;

  &.showError {
    border: solid 2px red;
  }
}

.innerAuthContainerPadded {
  padding: 25px 25px;
}

.verifyMessage {
  text-align: center;
}

.passwordHeader {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: -1px;
  color: $color-base-300;
  line-height: 40px;
  min-height: 50px;
  text-align: center;
}

.userEmail {
  font-size: 24px;
  color: $black;
  margin-top: -10px;
  margin-bottom: 10px;
}

.bottomRow {
  /* form styles */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
}

.styledInput {
  width: 325px;
  outline: none;
  height: 41px;
  margin: 4px auto 4px;
  padding: 0 16px;
  border-radius: 20px;
  text-align: center;
  letter-spacing: -0.3px;
  background: rgba($color-base-300, 0.25);
  border: none !important;

  &::placeholder {
    font-style: italic;
    color: $color-neutral-500;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0px;
    opacity: 1;
  }
}

.styledFieldError {
  position: initial;
  margin: 10px 26px 0 auto;
  display: flex;
  font-size: 12px;
  color: #ff0053;
}

.navButtons {
  // display: flex;
  // align-items: center;
  // margin: 10px 0 0;
  // width: 325px; // matches input
  // justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; // Ensure the navButtons div takes full width
  padding: 0 25px; // Add padding if needed for spacing
  font-size: 12px;
  // font-weight: 700;

  .forgotPassword {
    margin-right: auto; // Pushes the buttons container to the right
    text-decoration: none;
    padding-top: 7px;
    font-weight: 600;
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0 0;

    .backButton {
      margin-right: 0px; // Spacing between back and continue button
      font-weight: 600;
    }
  }
}

.continueButton {
  // Add better alignment if necessary
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.alertIcon {
  width: 14px;
  margin: -1px 5px auto auto;
}

.forgotPassword {
  cursor: pointer;
  text-decoration: underline;
  // Add other styles as needed
  font-size: 11px;
}

.vStack {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emailInputWrapper {
  position: relative;
  width: 340px;
}

.buttonGroup {
  display: flex;
  margin-top: 30px;
  align-self: flex-end;
  color: #2a2a2a;
  font-size: 12px;
  font-weight: 800;
}

.backButton {
  margin-right: 20px;
  color: #2a2a2a;
  font-size: 12px;
}

.resetMessage {
  font-size: 20px;
  width: 294px;
  margin: 26px auto 21px;
}

.expiredMessage {
  font-size: 20px;
  text-align: center;
}

.expiredMessageP2 {
  max-width: 70%;
  font-size: 16px;
  color: $color-neutral-500;
  letter-spacing: -0.4px;
  text-align: center;
  margin-bottom: 30px;
}

.errorBorder {
  border: 2px solid red !important;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-left: 25px;
}

.turnstileContainer {
  // position: relative;
  // top: 96px;
  margin-top: 15px;
}

.resetPasswordContainer {
  margin-top: 15px;
}

.resetPasswordMessage {
  font-size: 20px;
  width: 294px;
  margin: 26px auto 21px;
  padding-bottom: 10px;
  color: #2a2a2a;
  font-weight: 400;
}

.userErrorNumber {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.3px;
  margin-top: 8px;
}

.userErrorTitle {
  margin: 15px auto 9px;
  color: $color-base-300;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.3px;
  display: inline;

}

.errorLicense {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.errorLicenseExpiredTitle {
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
}

.userErrorDesc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
}

.hr {
  border: 1px solid $blue-200;
}

.infoLicenseExpired {
  padding: 5px 0;

  a {
    padding-left: 5px;
    font-weight: 400;
  }

  ol {
    list-style: decimal;
    background-color: red;
  }
}

ol.infoLicenseExpired {
  list-style: decimal;
  padding-left: 20px;
}

.infoLicenseExpiredLink {
  display: inline-flex;
  text-decoration: none;
  color: $color-base-300;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;

  svg {
    margin-left: 5px;
  }
}

.signInButton {
  margin-top: 20px;
}

.userError {
  justify-content: flex-start;
  min-height: auto;

  &.showError {
    border: none;
    width: 560px;
  }
}